import $ from 'jquery';
import 'lazysizes';
import 'lazysizes/plugins/unveilhooks/ls.unveilhooks';
import Headroom from 'headroom.js';
import 'slick-carousel';
import sal from 'sal.js';

const Mangrove = {
  init() {
    this.cache();
    this.events();
    this.initSal();
    this.initTestimonials();
    this.initDropdowns();
    this.heroSlider();
    this.instagramLink();
    this.formSubmission();
    this.headerSearch();
    this.initLanguageButtons();
    this.initHeaderLinks();
    this.translatePage();
    this.privacyCookie();
    this.customScreening();
  },
  cache() {
    this.$doc = $(document);
    this.$win = $(window);
    this.$html = $('html');
    this.$body = $('body');
    this.$mainWrap = $('main');
    this.$header = $('.js-header');
    this.$mobileNav = $('.js-mobile-nav');
    this.$hamburger = $('.js-hamburger');
  },
  events() {
    this.$doc.on('click', '.js-hamburger', event => this.showMobileNav(event));
    this.$doc.on('click', '.js-share', event => this.openShareWindow(event));
    this.$doc.on('click', '.js-mobile-nav .menu-item a', event =>
      this.showSubMenu(event)
    );
  },
  initSal() {
    sal({
      threshold: 0.1
    });
  },
  customScreening(){
    var codes = ['94901','94903','94904','94904','94912','94913','94914','94915','94920','94920','94920','94924','94924','94925','94929','94930','94933','94937','94938','94939','94940','94941','94942','94945','94946','94947','94948','94949','94950','94956','94957','94960','94963','94964','94965','94965','94966','94970','94971','94973','94974','94976','94977','94978','94979','94952','94954'];

    $(".screening-yes").on('click', function(){
      $(this).parent().parent().find($(".custom-screening__results")).hide();
      $(this).parent().parent().find($(".custom-screening__zipcode__wrap")).show();
      $(this).parent().parent().find($(".custom-screening__results")).hide();
      $(this).parent().parent().find($(".custom-screening__results__no")).hide();
      $(this).parent().parent().find($(".custom-screening__results__yes")).hide();
    });
    $(".screening-no").on('click', function(){
      $(this).parent().parent().find($(".custom-screening__results")).hide();
      $(this).parent().parent().find($(".custom-screening__zipcode__wrap")).hide();
      $(this).parent().parent().find($(".custom-screening__results")).show();
      $(this).parent().parent().find($(".custom-screening__results__no")).show();
      $(this).parent().parent().find($(".custom-screening__results__yes")).hide();
    });

    $(".screening-next").on('click', function(){
      var zipcode = $(this).parent().find($(".zipCode")).val();
      var correctZipcode = (codes.indexOf(zipcode) > -1);
      if(correctZipcode){
        $(this).parent().parent().parent().find($(".custom-screening__results")).show();
        $(this).parent().parent().parent().find($(".custom-screening__results__no")).hide();
        $(this).parent().parent().parent().find($(".custom-screening__results__yes")).show();
      }
      else{
        $(this).parent().parent().parent().find($(".custom-screening__results")).show();
        $(this).parent().parent().parent().find($(".custom-screening__results__yes")).hide();
        $(this).parent().parent().parent().find($(".custom-screening__results__no")).show();
      }
    });

  },
  privacyCookie(){
    var hasCookie = getCookie('privacy-accept');
    if(!hasCookie){
      $(".privacy-popup").fadeIn();
    }
    $('.privacy-popup__cta--close').on('click', function() {
      $(".privacy-popup").hide();
      setCookie('privacy-accept', 'true', 1);
    });

    $('.privacy-popup__close').on('click', function() {
      $(".privacy-popup").hide();
    });

    function setCookie(cname, cvalue, exdays) {
      var d = new Date();
      d.setTime(d.getTime() + (exdays*24*60*60*1000));
      var expires = "expires="+ d.toUTCString();
      document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
    }

    function getCookie(cname) {
      var name = cname + "=";
      var decodedCookie = decodeURIComponent(document.cookie);
      var ca = decodedCookie.split(';');
      for(var i = 0; i <ca.length; i++) {
        var c = ca[i];
        while (c.charAt(0) == ' ') {
          c = c.substring(1);
        }
        if (c.indexOf(name) == 0) {
          return c.substring(name.length, c.length);
        }
      }
      return "";
    }

  },
  translatePage(){
    $("#translatePage").on('click', function(){
      if($(this).text() != "English" && $(this).text() != "Inglés"){
        var a = document.querySelector("#google_translate_element select");
        a.selectedIndex=1;
        a.dispatchEvent(new Event('change'));
        $(this).text("Inglés");
        if($(".home-spanish").length){
          $(".home-engl").hide();
          $(".home-spanish").show();
        }
      }
      else{
        jQuery('#\\:1\\.container').contents().find('#\\:1\\.restore').click();
        $(this).text("Español");
        if($(".home-engl").length){
          $(".home-spanish").hide();
          $(".home-engl").show();
        }
      }
    });

     $(document).ready(function() {
        if (getCookie('googtrans') === "") {
           $("#translatePage").text("Español");
           if($(".home-engl").length){
             $(".home-spanish").hide();
             $(".home-engl").show();
           }
        }
        else{
          $("#translatePage").text("Inglés");
          if($(".home-spanish").length){
            $(".home-engl").hide();
            $(".home-spanish").show();
          }
        }
     });

     function getCookie(cname) {
       var name = cname + "=";
       var ca = document.cookie.split(';');
       for(var i = 0; i < ca.length; i++) {
         var c = ca[i];
         while (c.charAt(0) == ' ') {
           c = c.substring(1);
         }
         if (c.indexOf(name) == 0) {
           return c.substring(name.length, c.length);
         }
       }
       return "";
     }

  },
  initHeaderLinks(){
    var url = window.location.href;
    var links = $(".upper-nav__second-row a");
    jQuery.each( links, function( i, val ) {
      var href = $(this).attr('href');
      if(href === url) {
        $(this).addClass('link-active');
      }
    });
  },
  initLanguageButtons(){
    if($("body").hasClass("single-activities") || $("body").hasClass("single-resources") || $("body").hasClass("page-template-library")){
      var url = window.location.href;
      var links = $(".wpml-ls-legacy-list-horizontal a");
      if(url.includes('?lang=es')){
        var linkText = $(links).eq(1).text();
        if($(links).length === 2 && /Español/i.test(linkText)) {
          $(links).eq(1).addClass('active-lang');
        }
      }
      else{
        console.log('no es');
        var linkText = $(links).eq(1).text();
        if($(links).length === 2 && /Español/i.test(linkText)){
          console.log('ok ');
          $(links).eq(0).addClass('active-lang');
        }
      }
    }
  },
  showSubMenu(event) {
    const el = $(event.currentTarget).parent();
    if (el.hasClass('menu-item-has-children')) {
      event.preventDefault();
      el.toggleClass('is-active');
    }
  },
  headerSearch(){
    $(".js-search").on('click', function(){
      $(".js-header").addClass('has-active-search');
    });
    $(".js-search-hide").on('click', function(){
      $(".js-header").removeClass('has-active-search');
    });
  },
  instagramLink(){
    if($(".sbi_follow_btn").length){
      var igLink = $(".sbi_follow_btn a").attr('href');
      $(".instagram__link").attr('href', igLink);
    }
  },
  formSubmission(){
    $(document).ready(function(){
      if($(".gform_footer.top_label input[type='submit']").length){
        jQuery(".gform_footer.top_label input[type='submit']").bind('click', function(){
          console.log('clickeeed');
          hideText();
        });
      }
    });

    function hideText(){
      var wait = setInterval(function(){
        if($(".gform_confirmation_message").length){
          jQuery(".form-block__text").hide();
          clearInterval(wait);
        }
      },10);
    }
  },
  heroSlider(){
    if($(".js-hero-slider").length){
      $('.js-hero-slider').slick({
        autoplay: true,
        autoplaySpeed: 3000,
        dots: true,
        infinite: true,
        speed: 500,
        fade: true,
        cssEase: 'linear',
        arrows: false,
        buttons: false,
        responsive: [
           {
             breakpoint: 500,
             settings: {
               arrows: false,
               dots: true,
             }
           }
         ]
      });
    }
  },
  initDropdowns(){
    $(".dropdowns__btn").on('click', function(){
      $(".dropdowns__btn").attr('id', '');
      $(this).attr('id', 'currentDrop');
      $(".dropdowns__content").hide();
      $(".dropdowns__btn svg").css('transform','rotate(180deg)');
      var content = $(this).parent().find('.dropdowns__content');
      var icon = $(this).parent().find('svg');
      if($(content).hasClass('dropdowns__content--active')){
        $(content).slideUp().removeClass('dropdowns__content--active');
        $(icon).css('transform','rotate(180deg)');
      }
      else{
        $(".dropdowns__content").removeClass('dropdowns__content--active');
        $(content).slideToggle().addClass('dropdowns__content--active');
        $(icon).css('transform','rotate(270deg)');
      }
      $('html, body').animate({
        scrollTop: $("#currentDrop").offset().top
      }, 1)
    });
  },
  initTestimonials(){
    if($(".js-testimonials").length){
      $('.js-testimonials').slick({
        autoplay: true,
        autoplaySpeed: 3000,
        dots: false,
        infinite: true,
        speed: 500,
        fade: true,
        cssEase: 'linear',
        responsive: [
           {
             breakpoint: 500,
             settings: {
               arrows: false,
               dots: true,
             }
           }
         ]
      });
    }
  },
  openShareWindow(event) {
    let link = $(event.currentTarget).attr('href');
    if (link.substr(0, 6) != 'mailto') {
      event.preventDefault();
      window.open(link, '', 'width=600, height=400');
    }
  },
  initHeadroom() {
    let theHeader = document.querySelector('.js-header');
    let headroom = new Headroom(theHeader, { offset: 100, tolearnce: 5 });
    headroom.init();
  },
  showMobileNav(event) {
    event.preventDefault();
    this.$mobileNav.toggleClass('is-active');
    this.$hamburger.toggleClass('is-active');
    if (this.$mobileNav.hasClass('is-active')) {
      this.$hamburger.attr('aria-pressed', 'true');
    } else {
      this.$hamburger.removeAttr('aria-pressed');
    }
  }
};

Mangrove.init();
